import { ref } from 'vue';

const states = new Map();

export function useCollapse() {
  const registerCollapse = (id) => {
    if (typeof id !== 'string' || !id) {
      console.error('The provided ID is invalid.');
      return;
    }

    if (!states.has(id)) {
      states.set(id, ref(false));
    }
  };

  const toggleCollapseState = (id) => {
    const stateRef = states.get(id);
    if (stateRef) {
      stateRef.value = !stateRef.value;
    } else {
      console.error(`State for ID ${id} not found!`);
    }
  };

  const getCollapseState = (id) => states.get(id);

  return {
    registerCollapse,
    toggleCollapseState,
    getCollapseState,
  };
}
