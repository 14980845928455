import revive_payload_client_NL2ro8cm5k from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset@0.60.3_async-validator@4_6adlsuak5shhn42gv2m3o25nei/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_MJkV7Kd0qL from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset@0.60.3_async-validator@4_6adlsuak5shhn42gv2m3o25nei/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7krA6NB3Y8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset@0.60.3_async-validator@4_6adlsuak5shhn42gv2m3o25nei/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_C4uYkZ7Zoo from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset@0.60.3_async-validator@4_6adlsuak5shhn42gv2m3o25nei/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_tAZn96GF9E from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset@0.60.3_async-validator@4_6adlsuak5shhn42gv2m3o25nei/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_5kYAkctXmR from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.17.2_typescript@5.4.5_vue@3.4.27_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/front/.nuxt/components.plugin.mjs";
import prefetch_client_Xlt811AM2M from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset@0.60.3_async-validator@4_6adlsuak5shhn42gv2m3o25nei/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/vercel/path0/apps/front/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/vercel/path0/apps/front/.nuxt/element-plus-injection.plugin.mjs";
import chunk_reload_client_G3DW6UXFOs from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset@0.60.3_async-validator@4_6adlsuak5shhn42gv2m3o25nei/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import scrollspy_client_mRTZl1UJDi from "/vercel/path0/apps/front/src/plugins/scrollspy.client.js";
import firebase_client_lOvfsw6Ppv from "/vercel/path0/apps/front/src/plugins/firebase.client.ts";
import form_fhHqzO4Fku from "/vercel/path0/apps/front/src/plugins/form.js";
import scroll_to_directive_mvfaQkBu2F from "/vercel/path0/apps/front/src/plugins/scroll-to-directive.js";
import scroll_to_helper_rsMANC87cn from "/vercel/path0/apps/front/src/plugins/scroll-to-helper.js";
import toggle_collapse_directive_cygon8PgEz from "/vercel/path0/apps/front/src/plugins/toggle-collapse-directive.js";
import vee_validate_client_45gWhfAHXv from "/vercel/path0/apps/front/src/plugins/vee-validate.client.js";
import vue_click_away_QoBmAA3ck9 from "/vercel/path0/apps/front/src/plugins/vue-click-away.js";
import vue_gtm_client_3YPaNVLi9H from "/vercel/path0/apps/front/src/plugins/vue-gtm.client.js";
import vue_social_sharing_glaVIJc3fZ from "/vercel/path0/apps/front/src/plugins/vue-social-sharing.js";
import wp_api_provider_plugin_GTJSRz7MzG from "/vercel/path0/apps/front/src/plugins/wp-api-provider.plugin.js";
export default [
  revive_payload_client_NL2ro8cm5k,
  unhead_MJkV7Kd0qL,
  router_7krA6NB3Y8,
  payload_client_C4uYkZ7Zoo,
  check_outdated_build_client_tAZn96GF9E,
  plugin_vue3_5kYAkctXmR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Xlt811AM2M,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  chunk_reload_client_G3DW6UXFOs,
  scrollspy_client_mRTZl1UJDi,
  firebase_client_lOvfsw6Ppv,
  form_fhHqzO4Fku,
  scroll_to_directive_mvfaQkBu2F,
  scroll_to_helper_rsMANC87cn,
  toggle_collapse_directive_cygon8PgEz,
  vee_validate_client_45gWhfAHXv,
  vue_click_away_QoBmAA3ck9,
  vue_gtm_client_3YPaNVLi9H,
  vue_social_sharing_glaVIJc3fZ,
  wp_api_provider_plugin_GTJSRz7MzG
]