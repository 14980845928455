import axios from 'axios';
import { reactive } from 'vue';
import he from 'he';
import { defineNuxtPlugin } from '#imports';

import { useTruncateExcerpt } from '@/composables/useTruncateExcerpt';
import { useFormatDate } from '@/composables/useFormatDate';

export default defineNuxtPlugin((nuxtApp) => {
  const { truncateExcerpt } = useTruncateExcerpt();
  const { formatDate } = useFormatDate();

  const URL = `https://cp.trueislam.co.uk/wp-json/wp/v2/:endpoint`;

  const categories = {
    en: {
      blog: '15',
      services: '17',
      articles: '5',
      elevenPoints: '6',
      beliefs: '2',
    },
  };

  const wpAPI = axios.create({
    // baseURL: URL,
    transformResponse: [
      function (data) {
        return JSON.parse(data); // You'd always need to parse JSON first before any other transformations
      },
    ],
  });

  function getArticlesImageUrl(post) {
    const { _embedded = null } = post;
    const embedded = _embedded['wp:featuredmedia'] ?? null;
    if (embedded) {
      return embedded['0']?.media_details?.sizes?.full?.source_url || null;
    }
    return null;
  }

  function transformPostData(rawData) {
    return rawData.map((post) => {
      const {
        slug = '',
        title = '',
        link = '',
        content = '',
        excerpt = '',
        date_gmt: dateGmt = '',
        id = '',
      } = post;

      return reactive({
        image: getArticlesImageUrl(post),
        slug,
        link: link || null,
        id: id || null,
        title: he.decode(title?.rendered) || null,
        content: content?.rendered || null,
        truncateContent: truncateExcerpt(excerpt?.rendered) || null,
        excerpt: excerpt?.rendered || null,
        date: formatDate(dateGmt) || null,
      });
    });
  }

  function fetchFromWP(endpoint, params, lang = 'en') {
    const url = URL.replace(':endpoint', endpoint);
    return wpAPI.get(url, {
      params: {
        _embed: true,
        ...params,
        categories: categories[lang][params.categories],
      },
      transformResponse: [
        ...wpAPI.defaults.transformResponse,
        function (data) {
          if (!Array.isArray(data)) {
            console.error('Data from the API is not an array:', data);
            return data;
          }
          return transformPostData(data);
        },
      ],
    });
  }

  const ElevenPoints = {
    getSingle(endpoint, slug, lang = 'en') {
      return fetchFromWP(endpoint, { categories: 'elevenPoints', slug }, lang);
    },
    getAll(endpoint, lang = 'en') {
      return fetchFromWP(
        endpoint,
        { categories: 'elevenPoints', per_page: 11 },
        lang,
      );
    },
  };

  const Articles = {
    getSingle(endpoint, slug, lang = 'en') {
      return fetchFromWP(endpoint, { categories: 'articles', slug }, lang);
    },
    getAll(endpoint, perPage = 10, page = 1, lang = 'en') {
      return fetchFromWP(
        endpoint,
        { categories: 'articles', page, per_page: perPage },
        lang,
      );
    },
  };

  const Beliefs = {
    getSingle(endpoint, slug, lang = 'en') {
      return fetchFromWP(endpoint, { categories: 'beliefs', slug }, lang);
    },
    getAll(endpoint, perPage = 10, page = 1, lang = 'en') {
      return fetchFromWP(
        endpoint,
        { categories: 'beliefs', page, per_page: perPage },
        lang,
      );
    },
  };

  const factories = {
    elevenPoints: ElevenPoints,
    articles: Articles,
    beliefs: Beliefs,
  };

  nuxtApp.provide('api', factories);
});
