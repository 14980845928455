import { useCollapse } from '../composables/useCollapse';

const toggleDirective = {
  beforeMount(el, binding) {
    const { toggleCollapseState, getCollapseState } = useCollapse();

    const clickHandler = () => {
      toggleCollapseState(binding.value);
      const isExpanded = getCollapseState(binding.value);
      el.setAttribute('aria-expanded', isExpanded.value.toString());
      if (isExpanded.value) {
        el.classList.remove('collapsed');
        el.classList.add('not-collapsed');
      } else {
        el.classList.remove('not-collapsed');
        el.classList.add('collapsed');
      }
    };

    el.addEventListener('click', clickHandler);
    el._vueClickHandler = clickHandler;
  },

  unmounted(el) {
    if (el._vueClickHandler) {
      el.removeEventListener('click', el._vueClickHandler);
    }
  },
};

export default toggleDirective;
