const toggleDirective = {
  mounted(el, binding) {
    // Define the listener function and store it on the element for later reference
    const onClick = () => {
      const currentUrl = new URL(window.location);

      // Check if binding.value is undefined or doesn't have the el property
      if (!binding.value || !binding.value.el) return;

      const options = binding.value;
      if (options.page) {
        currentUrl.pathname = options.page;
        currentUrl.hash = options.el;
        window.location.href = currentUrl.href;
      }
      const targetElement = document.querySelector(options.el);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: options.easing || 'smooth',
          block: options.y ? 'start' : 'center',
          inline: options.x ? 'start' : 'center',
        });
      }
    };

    // Attach the event listener to the element
    el.addEventListener('click', onClick);

    // Store the listener function on the element for future removal
    el._toggleClickListener = onClick;
  },
  beforeUnmount(el) {
    // Use the stored listener function for removal
    if (el._toggleClickListener) {
      el.removeEventListener('click', el._toggleClickListener);
      // Clean up the reference to prevent memory leaks
      delete el._toggleClickListener;
    }
  },
};

export default toggleDirective;
