// import Vue from 'vue'
import { nextTick } from 'vue';
import { defineNuxtPlugin } from '#app';

// const app = Vue.createApp({})

// app.config.globalProperties.$scrollTo  =

export default defineNuxtPlugin(() => {
  return {
    provide: {
      scrollTo: (element, options = {}) => {
        nextTick(() => {
          window.scrollTo({
            top: element.offsetTop + (options.offset || 0),
            left: element.offsetLeft,
            behavior: options.easing || 'smooth',
          });
        });
      },
    },
  };
});
