import { defineNuxtPlugin } from '#app';
import ScrollSpy from 'bootstrap/js/dist/scrollspy.js';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      scrollSpy: (element, options) => {
        /* eslint-disable */
        new ScrollSpy(element, options);
        /* eslint-enable */
      },
    },
  };
});
