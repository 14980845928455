import { default as _91slug_93QtMPtZSI7vMeta } from "/vercel/path0/apps/front/src/pages/articles/[slug].vue?macro=true";
import { default as index4GtCwOhJvwMeta } from "/vercel/path0/apps/front/src/pages/articles/index.vue?macro=true";
import { default as _91slug_93elUDGsjEUEMeta } from "/vercel/path0/apps/front/src/pages/beliefs/[slug].vue?macro=true";
import { default as index2BUXtn8Hs9Meta } from "/vercel/path0/apps/front/src/pages/beliefs/index.vue?macro=true";
import { default as campaignsXZ8putBwHuMeta } from "/vercel/path0/apps/front/src/pages/campaigns.vue?macro=true";
import { default as contact_45usvNFgWlULX9Meta } from "/vercel/path0/apps/front/src/pages/contact-us.vue?macro=true";
import { default as discover1SQWUqbY3iMeta } from "/vercel/path0/apps/front/src/pages/discover.vue?macro=true";
import { default as _91slug_93VFjAwWVpGJMeta } from "/vercel/path0/apps/front/src/pages/elevenpoints/[slug].vue?macro=true";
import { default as indexRutPcguWcNMeta } from "/vercel/path0/apps/front/src/pages/elevenpoints/index.vue?macro=true";
import { default as exhibitionsNRSkcksociMeta } from "/vercel/path0/apps/front/src/pages/exhibitions.vue?macro=true";
import { default as indexDxaxxOHueXMeta } from "/vercel/path0/apps/front/src/pages/index.vue?macro=true";
import { default as indexa0p2nCB2aVMeta } from "/vercel/path0/apps/front/src/pages/stopww3/index.vue?macro=true";
import { default as introductionX8NtaCKHueMeta } from "/vercel/path0/apps/front/src/pages/stopww3/introduction.vue?macro=true";
import { default as the_45campaign5mSdxWyI49Meta } from "/vercel/path0/apps/front/src/pages/stopww3/the-campaign.vue?macro=true";
import { default as the_45critical_45need_45for_45peacezpfIv1JJAGMeta } from "/vercel/path0/apps/front/src/pages/stopww3/the-critical-need-for-peace.vue?macro=true";
import { default as the_45prophecyRZ9gkEC6p5Meta } from "/vercel/path0/apps/front/src/pages/stopww3/the-prophecy.vue?macro=true";
import { default as submit_45your_45question9q5UN8955TMeta } from "/vercel/path0/apps/front/src/pages/submit-your-question.vue?macro=true";
import { default as terms_45of_45service32WdWTg674Meta } from "/vercel/path0/apps/front/src/pages/terms-of-service.vue?macro=true";
import { default as testDbI0tyS0wFMeta } from "/vercel/path0/apps/front/src/pages/test.vue?macro=true";
import { default as the_45messiah_45has_45comeoGcxS7xUZcMeta } from "/vercel/path0/apps/front/src/pages/the-messiah-has-come.vue?macro=true";
import { default as topics_45indexPvT2vLWQUbMeta } from "/vercel/path0/apps/front/src/pages/topics-index.vue?macro=true";
import { default as use_45of_45cookiesBW7cdhzmLiMeta } from "/vercel/path0/apps/front/src/pages/use-of-cookies.vue?macro=true";
export default [
  {
    name: _91slug_93QtMPtZSI7vMeta?.name ?? "articles-slug",
    path: _91slug_93QtMPtZSI7vMeta?.path ?? "/articles/:slug()",
    meta: _91slug_93QtMPtZSI7vMeta || {},
    alias: _91slug_93QtMPtZSI7vMeta?.alias || [],
    redirect: _91slug_93QtMPtZSI7vMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4GtCwOhJvwMeta?.name ?? "articles",
    path: index4GtCwOhJvwMeta?.path ?? "/articles",
    meta: index4GtCwOhJvwMeta || {},
    alias: index4GtCwOhJvwMeta?.alias || [],
    redirect: index4GtCwOhJvwMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93elUDGsjEUEMeta?.name ?? "beliefs-slug",
    path: _91slug_93elUDGsjEUEMeta?.path ?? "/beliefs/:slug()",
    meta: _91slug_93elUDGsjEUEMeta || {},
    alias: _91slug_93elUDGsjEUEMeta?.alias || [],
    redirect: _91slug_93elUDGsjEUEMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/beliefs/[slug].vue").then(m => m.default || m)
  },
  {
    name: index2BUXtn8Hs9Meta?.name ?? "beliefs",
    path: index2BUXtn8Hs9Meta?.path ?? "/beliefs",
    meta: index2BUXtn8Hs9Meta || {},
    alias: index2BUXtn8Hs9Meta?.alias || [],
    redirect: index2BUXtn8Hs9Meta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/beliefs/index.vue").then(m => m.default || m)
  },
  {
    name: campaignsXZ8putBwHuMeta?.name ?? "campaigns",
    path: campaignsXZ8putBwHuMeta?.path ?? "/campaigns",
    meta: campaignsXZ8putBwHuMeta || {},
    alias: campaignsXZ8putBwHuMeta?.alias || [],
    redirect: campaignsXZ8putBwHuMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/campaigns.vue").then(m => m.default || m)
  },
  {
    name: contact_45usvNFgWlULX9Meta?.name ?? "contact-us",
    path: contact_45usvNFgWlULX9Meta?.path ?? "/contact-us",
    meta: contact_45usvNFgWlULX9Meta || {},
    alias: contact_45usvNFgWlULX9Meta?.alias || [],
    redirect: contact_45usvNFgWlULX9Meta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: discover1SQWUqbY3iMeta?.name ?? "discover",
    path: discover1SQWUqbY3iMeta?.path ?? "/discover",
    meta: discover1SQWUqbY3iMeta || {},
    alias: discover1SQWUqbY3iMeta?.alias || [],
    redirect: discover1SQWUqbY3iMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/discover.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VFjAwWVpGJMeta?.name ?? "elevenpoints-slug",
    path: _91slug_93VFjAwWVpGJMeta?.path ?? "/elevenpoints/:slug()",
    meta: _91slug_93VFjAwWVpGJMeta || {},
    alias: _91slug_93VFjAwWVpGJMeta?.alias || [],
    redirect: _91slug_93VFjAwWVpGJMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/elevenpoints/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexRutPcguWcNMeta?.name ?? "elevenpoints",
    path: indexRutPcguWcNMeta?.path ?? "/elevenpoints",
    meta: indexRutPcguWcNMeta || {},
    alias: indexRutPcguWcNMeta?.alias || [],
    redirect: indexRutPcguWcNMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/elevenpoints/index.vue").then(m => m.default || m)
  },
  {
    name: exhibitionsNRSkcksociMeta?.name ?? "exhibitions",
    path: exhibitionsNRSkcksociMeta?.path ?? "/exhibitions",
    meta: exhibitionsNRSkcksociMeta || {},
    alias: exhibitionsNRSkcksociMeta?.alias || [],
    redirect: exhibitionsNRSkcksociMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/exhibitions.vue").then(m => m.default || m)
  },
  {
    name: indexDxaxxOHueXMeta?.name ?? "index",
    path: indexDxaxxOHueXMeta?.path ?? "/",
    meta: indexDxaxxOHueXMeta || {},
    alias: indexDxaxxOHueXMeta?.alias || [],
    redirect: indexDxaxxOHueXMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexa0p2nCB2aVMeta?.name ?? "stopww3",
    path: indexa0p2nCB2aVMeta?.path ?? "/stopww3",
    meta: indexa0p2nCB2aVMeta || {},
    alias: indexa0p2nCB2aVMeta?.alias || [],
    redirect: indexa0p2nCB2aVMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/stopww3/index.vue").then(m => m.default || m)
  },
  {
    name: introductionX8NtaCKHueMeta?.name ?? "stopww3-introduction",
    path: introductionX8NtaCKHueMeta?.path ?? "/stopww3/introduction",
    meta: introductionX8NtaCKHueMeta || {},
    alias: introductionX8NtaCKHueMeta?.alias || [],
    redirect: introductionX8NtaCKHueMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/stopww3/introduction.vue").then(m => m.default || m)
  },
  {
    name: the_45campaign5mSdxWyI49Meta?.name ?? "stopww3-the-campaign",
    path: the_45campaign5mSdxWyI49Meta?.path ?? "/stopww3/the-campaign",
    meta: the_45campaign5mSdxWyI49Meta || {},
    alias: the_45campaign5mSdxWyI49Meta?.alias || [],
    redirect: the_45campaign5mSdxWyI49Meta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/stopww3/the-campaign.vue").then(m => m.default || m)
  },
  {
    name: the_45critical_45need_45for_45peacezpfIv1JJAGMeta?.name ?? "stopww3-the-critical-need-for-peace",
    path: the_45critical_45need_45for_45peacezpfIv1JJAGMeta?.path ?? "/stopww3/the-critical-need-for-peace",
    meta: the_45critical_45need_45for_45peacezpfIv1JJAGMeta || {},
    alias: the_45critical_45need_45for_45peacezpfIv1JJAGMeta?.alias || [],
    redirect: the_45critical_45need_45for_45peacezpfIv1JJAGMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/stopww3/the-critical-need-for-peace.vue").then(m => m.default || m)
  },
  {
    name: the_45prophecyRZ9gkEC6p5Meta?.name ?? "stopww3-the-prophecy",
    path: the_45prophecyRZ9gkEC6p5Meta?.path ?? "/stopww3/the-prophecy",
    meta: the_45prophecyRZ9gkEC6p5Meta || {},
    alias: the_45prophecyRZ9gkEC6p5Meta?.alias || [],
    redirect: the_45prophecyRZ9gkEC6p5Meta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/stopww3/the-prophecy.vue").then(m => m.default || m)
  },
  {
    name: submit_45your_45question9q5UN8955TMeta?.name ?? "submit-your-question",
    path: submit_45your_45question9q5UN8955TMeta?.path ?? "/submit-your-question",
    meta: submit_45your_45question9q5UN8955TMeta || {},
    alias: submit_45your_45question9q5UN8955TMeta?.alias || [],
    redirect: submit_45your_45question9q5UN8955TMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/submit-your-question.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45service32WdWTg674Meta?.name ?? "terms-of-service",
    path: terms_45of_45service32WdWTg674Meta?.path ?? "/terms-of-service",
    meta: terms_45of_45service32WdWTg674Meta || {},
    alias: terms_45of_45service32WdWTg674Meta?.alias || [],
    redirect: terms_45of_45service32WdWTg674Meta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: testDbI0tyS0wFMeta?.name ?? "test",
    path: testDbI0tyS0wFMeta?.path ?? "/test",
    meta: testDbI0tyS0wFMeta || {},
    alias: testDbI0tyS0wFMeta?.alias || [],
    redirect: testDbI0tyS0wFMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/test.vue").then(m => m.default || m)
  },
  {
    name: the_45messiah_45has_45comeoGcxS7xUZcMeta?.name ?? "the-messiah-has-come",
    path: the_45messiah_45has_45comeoGcxS7xUZcMeta?.path ?? "/the-messiah-has-come",
    meta: the_45messiah_45has_45comeoGcxS7xUZcMeta || {},
    alias: the_45messiah_45has_45comeoGcxS7xUZcMeta?.alias || [],
    redirect: the_45messiah_45has_45comeoGcxS7xUZcMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/the-messiah-has-come.vue").then(m => m.default || m)
  },
  {
    name: topics_45indexPvT2vLWQUbMeta?.name ?? "topics-index",
    path: topics_45indexPvT2vLWQUbMeta?.path ?? "/topics-index",
    meta: topics_45indexPvT2vLWQUbMeta || {},
    alias: topics_45indexPvT2vLWQUbMeta?.alias || [],
    redirect: topics_45indexPvT2vLWQUbMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/topics-index.vue").then(m => m.default || m)
  },
  {
    name: use_45of_45cookiesBW7cdhzmLiMeta?.name ?? "use-of-cookies",
    path: use_45of_45cookiesBW7cdhzmLiMeta?.path ?? "/use-of-cookies",
    meta: use_45of_45cookiesBW7cdhzmLiMeta || {},
    alias: use_45of_45cookiesBW7cdhzmLiMeta?.alias || [],
    redirect: use_45of_45cookiesBW7cdhzmLiMeta?.redirect,
    component: () => import("/vercel/path0/apps/front/src/pages/use-of-cookies.vue").then(m => m.default || m)
  }
]