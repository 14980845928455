const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function getDate(iso) {
  const dateObj = new Date(iso);
  const [day, year, monthIndex] = [
    dateObj.getUTCDate(),
    dateObj.getUTCFullYear(),
    dateObj.getUTCMonth(),
  ];
  return `${MONTH_NAMES[monthIndex]} ${day}, ${year}`;
}

export function useFormatDate() {
  const formatDate = (iso) => {
    return getDate(iso);
  };

  return {
    formatDate,
  };
}
