export function useTruncateExcerpt() {
  const truncateExcerpt = (excerpt) => {
    const cleanExcerpt = excerpt.substring(3, excerpt.length - 5);
    return cleanExcerpt.length > 90
      ? `${cleanExcerpt.substring(0, 90)}...`
      : cleanExcerpt;
  };

  return {
    truncateExcerpt,
  };
}
