import { type FirebaseApp, initializeApp, getApps } from 'firebase/app';
import { type Auth, getAuth } from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';
import { defineNuxtPlugin, useRuntimeConfig } from '#imports';

declare module '#app' {
  interface NuxtApp {
    $firebaseApp: FirebaseApp;
    $firestore: Firestore;
    $firebaseAuth: Auth;
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const firebaseConfig = {
    apiKey: config.public.firebaseApiKey,
    authDomain: config.public.firebaseAuthDomain,
    projectId: config.public.firebaseProjectId,
    storageBucket: config.public.firebaseStorageBucket,
    messagingSenderId: config.public.firebaseMessagingSenderId,
    appId: config.public.firebaseAppId,
    measurementId: config.public.firebaseMeasurementId,
  };

  const apps = getApps();

  const firebaseApp = !apps.length ? initializeApp(firebaseConfig) : apps[0];

  const db = getFirestore(firebaseApp);
  // TODO por que esto sirve?
  // const firebaseAuth = initializeAuth(firebaseApp);
  const firebaseAuth = getAuth(firebaseApp);

  nuxtApp.vueApp.provide('firestoreConf', firebaseConfig);
  nuxtApp.provide('firestoreConf', firebaseConfig);

  nuxtApp.vueApp.provide('auth', firebaseAuth);
  nuxtApp.provide('auth', firebaseAuth);

  nuxtApp.vueApp.provide('firestore', firebaseApp);
  nuxtApp.provide('firestore', firebaseApp);

  return { provide: { db } };
});
